import { createApp } from "vue";
// 引入组件
import App from "./App";
import ElementUI from 'element-plus';

// 引入资源
import router from "./router";

import './assets/font/font.css'
import {Base64} from "js-base64"
import VueClipboard from 'vue-clipboard2';
// 创建app
const app = createApp(App);
app.use(ElementUI);
app.use(VueClipboard);
app.use(Base64)

/* 引入全局CSS */
import "@/assets/css/reset.css"; // 重置样式
import "@/assets/css/global.css"; // 全局样式
import "@/assets/css/compatible.css"; // 响应式兼容

// 引入util
import tool from "@/util/tool.js";
app.config.globalProperties.$tool = tool;

/* 全局请求axios */
import api from "@/axios/api"; //这里引入的是js文件
app.config.globalProperties.$api = api;

/* 全局请求axios */
import buss from "@/axios/buss"; //这里引入的是js文件
app.config.globalProperties.$buss = buss;

/* 全局工具 */
/*import checkStr from "@/util/checkStr.js"; //这里引入的是js文件

app.config.globalProperties.$checkStr = checkStr;*/
// 注入路由
app.use(router);


// 挂载实例
app.mount("#app");
