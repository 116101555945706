import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
        meta: {
            title: '30sec',

        }
    },
   /* {
        path: '/Join',
        name: 'Join',
        component: () =>
            import ('../views/Join.vue'),
        meta: {
            title: '30sec'
        }
    },
    {
        path: '/Wallet',
        name: 'Wallet',
        component: () =>
            import ('../views/Wallet.vue'),
        meta: {
            title: '30sec'
        }
    },*/
    {
        path: '/share/:id',
        name: 'SharePath',
        component: () =>
            import ('../views/app/SharePath.vue'),
        meta: {
            title: '30sec'
        }
    },
    {
        path: '/shareApp',
        name: 'shareApp',
        component: () =>
            import ('../views/app/shareApp.vue'),
        meta: {
            title: '30sec'
        }
    },

    {
        path: '/test',
        name: 'test',
        component: () =>
            import ('../views/test.vue'),
        meta: {
            title: '30sec'
        }
    },
]

const router = createRouter({
    history: createWebHistory(""),
    routes
})

export default router