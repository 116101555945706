import axios from "axios";
import router from '../router'
import CryptoJS from "crypto-js";


/****** 创建axios实例 ******/
const service = axios.create({
    baseURL: "", // api的base_url
    // baseURL: '/api', // api的base_url
    timeout: 5000000 // 请求超时时间
});

service.interceptors.request.use(config => {
        // 修改请求头信息
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = JSON.parse(localStorage.getItem('token'))
        }
        return config
    })
    //axios请求拦截
service.interceptors.response.use(
    res => { //成功请求到数据

        if (res.data.code == '200'||res.data.code == 200) {
            return res.data
        } else if (res.data.code == '401') {
            localStorage.clear();
            router.push({ path: "/login" })
        } else {
            return res;
        }
    },
    error => { //响应错误处理
        console.log('error');
        console.log(error);
        console.log(JSON.stringify(error));
        return Promise.reject(error)
    }
);

//请求方法封装，拷贝直接用~
export default {
    GET(url, data = {}) {
        return service({
            url: url,
            method: 'get',
            params: data,
            headers: {
                'Content-Type': 'application/json'
            },
            data: ""
        })
    },

    POST(url, data = {}) {
        return service({
            url: url,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    },

    PUT(url, data = {}) {
        return service({
            url: url,
            method: 'put',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    },

    DELETE(url, data = {}) {
        return service({
            url: url,
            method: 'delete',
            data: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
    },
    encryptCBC(word, keyStr, ivStr) {
        keyStr = keyStr ? keyStr : "7a0o*^2&c-q+HbcALXGF59mcbqYxgga5";
        ivStr = ivStr ? ivStr : "3XwIK*y&zGwQVAIi";
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);
        let srcs = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
        return encrypted.toString()
    },
    urlencoude(e){
        return encodeURIComponent(e)
    }
}